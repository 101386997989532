import { useLazyQuery } from "@apollo/client";
import {
  makeStyles,
  Theme,
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Container,
  Fab,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GET_BOOKING } from "../../../../graphql/bookings/getBookingQuery";
import { ICustomer } from "../../../../reducers/bookings/types";
import { IAppState } from "../../../../store";
import { getLocalizedBookingSyntex } from "../../../../utils/localized.syntex";
import { TopBar } from "../../../TopBar/TopBar";
import { DateTime as d } from "luxon";
import { ITestDriveBookingDetails } from "../../../../reducers/testDriveBooking/types";
import { CustomerType } from "../../../../reducers/auth/types";
import { socket } from "../../../../utils/socket";
import { SOCKET_EVENTS } from "../../utils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: "0 1rem",
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    marginBottom: theme.spacing(0.5),
  },
  value: {
    fontSize: "1rem",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  bottomBar: {
    height: "60px",
    backgroundColor: "#fff",
    boxShadow: "0 0 20px 1px rgba(0,0,0,0.06)",
    padding: "8px 60px",
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flex: 1,
    overflow: "hidden",
    justifyContent: "center",
    zIndex: 900,
  },
}));

interface ITestDriveBookingSummary {
  id: string;
  bookingReference: string;
  vehicle: string;
  testDriveBookingDetails: ITestDriveBookingDetails;
  branchName: string;
  branchAddress: string;
  bookingTime: string;
  customer: ICustomer;
}

const TestDriveBookingConfirmation: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [testDriveBookingSummary, setTestDriveBookingSummary] =
    useState<ITestDriveBookingSummary>();
  const organisation = useSelector(
    (state: IAppState) =>
      state.consumerWebsiteReducer.consumerWebsite.organisation
  );
  const branchData = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches
  );
  const authUser = useSelector(
    (state: IAppState) => state.authReducer.authUser
  );
  const country = organisation!.address.country;

  const [loadBooking, { loading: bookingDataLoading }] = useLazyQuery(
    GET_BOOKING,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ consumerGetBooking }) => {
        if (
          consumerGetBooking &&
          consumerGetBooking.testDriveBookingDetails &&
          consumerGetBooking.currentBookingSchedules &&
          consumerGetBooking.currentBookingSchedules.length &&
          branchData
        ) {
          const selectedBranch = branchData.find(
            (branch) => branch.id === consumerGetBooking.branchId
          );
          const testDriveBookingSummary = {
            id: consumerGetBooking.id,
            vehicle:
              consumerGetBooking.currentBookingSchedules[0].vehicle.make +
              " " +
              consumerGetBooking.currentBookingSchedules[0].vehicle.model,
            testDriveBookingDetails: consumerGetBooking.testDriveBookingDetails,
            bookingTime: d
              .fromISO(consumerGetBooking.pickupDateTime)
              .toUTC()
              .toFormat("dd LLLL yyyy 'at' hh':'mm a"),
            bookingReference: consumerGetBooking.referenceNumber,
            branchName: selectedBranch?.name || "N/A",
            branchAddress: selectedBranch?.address?.fullAddress || "N/A",
            customer: consumerGetBooking.customer,
          };
          if (consumerGetBooking.customerType === CustomerType.BUSINESS) {
            testDriveBookingSummary.customer = {
              firstName: consumerGetBooking.businessCustomer.contact.firstName,
              lastName: consumerGetBooking.businessCustomer.contact.lastName,
              phoneNumber:
                consumerGetBooking.businessCustomer.contact.phoneNumber,
              email: consumerGetBooking.businessCustomer.contact.email,
              location: consumerGetBooking.businessCustomer.registeredAddress,
            };
          } else {
            testDriveBookingSummary.customer = consumerGetBooking.customer;
          }
          setTestDriveBookingSummary(testDriveBookingSummary);
        }
      },
    }
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const bookingId = params.get("id");
    if (bookingId) {
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_BOOKING, { bookingId: bookingId });
      return loadBooking({
        variables: {
          bookingId: bookingId,
        },
      });
    }
  }, [loadBooking, location]);

  useEffect(() => {
    socket.auth = {
      userId: authUser?._id,
    };
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, [authUser?._id]);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_BOOKING, (data) => {
      loadBooking({
        variables: {
          bookingId: data.bookingId,
        },
      });
    });

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_BOOKING);
    };
  }, [loadBooking]);

  if (!testDriveBookingSummary || bookingDataLoading) {
    return (
      <div
        style={{
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <TopBar height={70} logoSize={44} />
      <Container style={{ marginTop: 75 }}>
        <Grid item container xs={12}>
          <Paper className={classes.root} elevation={0}>
            <Typography variant="h1" className={classes.header}>
              {getLocalizedBookingSyntex(country)} Confirmation
            </Typography>

            <Box className={classes.section}>
              <Typography variant="h3" gutterBottom>
                Your {getLocalizedBookingSyntex(country)} Details
              </Typography>
            </Box>
            <Box className={classes.section}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>
                    {getLocalizedBookingSyntex(country)} Reference
                  </Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.bookingReference}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Model</Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.vehicle}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>
                    Booking Type
                  </Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.testDriveBookingDetails.type}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>
                    Booking Time
                  </Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.bookingTime}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.section}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Experience</Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.testDriveBookingDetails.experience}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Dealership</Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.branchName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>
                    Dealership Address
                  </Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.branchAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.section}>
              <Typography variant="h3" gutterBottom>
                Customer Details
              </Typography>
            </Box>

            <Box className={classes.section}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Customer</Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.customer.firstName}{" "}
                    {testDriveBookingSummary.customer.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Phone</Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.customer.phoneNumber.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Address</Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.customer.location.fullAddress}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Email</Typography>
                  <Typography className={classes.value}>
                    {testDriveBookingSummary.customer.email}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item container xs={12} className={classes.bottomBar}>
          <Fab
            aria-label="return-home"
            variant="extended"
            size="medium"
            onClick={() => {
              history.push(`/`);
            }}
            style={{
              backgroundColor: "var(--primary-color)",
            }}
          >
            {`New ${getLocalizedBookingSyntex(country)}`}
          </Fab>
        </Grid>
      </Container>
    </div>
  );
};

export default TestDriveBookingConfirmation;
