import { gql } from "apollo-boost";

export const GET_ORGANISATION = gql`
  query consumerGetOrganisation {
    consumerGetOrganisation {
      id
      autoRateType
      autoChargeEnabled
      closeGroupSharingEnabled
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      locale
      name
      emailId
      phoneNumber
      termsAndConditionsUrl
      logoUrl
      owner {
        id
        email
      }
      currency
      orgVatNumber
      orgRegNumber
      stripeAccountId
      requiresCustomerVerification
      allowSubscriptionBookings
      crossLocationBookingEnabled
      branches {
        id
        timeZone
        name
        phoneNumber
        emailId
        depositCollection
        locations {
          id
          name
          branch {
            name
            id
          }
        }
        owner {
          id
          email
        }
        addTaxOptIn
        taxes {
          id
          title
          rate
          type
          value
          subdivisionOptIn
          subdivisionType
          subdivisionRate
          subdivisionValue
        }
      }
    }
  }
`;

export const GET_APP_SETTING = gql`
  query consumerGetAppSetting {
    consumerGetAppSetting {
      id
      allowedRateTypes
      allowedBillingFrequencies
      appName
      organisationId
      branchIds
      privacyPolicyURL
      tenantId
      provider
      callbackUris
      clientId
      clientSecret
      firstParty
      companyWebsite
      bookingTypes
      pcoRentalOnly
      hideInsurance
      subscriptionSettings {
        dvlaVerificationEnabled
        creditCheckEnabled
        idvEnabled
      }
      termsAndConditionsURL
      branches {
        id
        timeZone
        name
        emailId
        depositCollection
        distanceUnit
        overbookingAllowed
        locations {
          id
          name
          branch {
            name
            id
          }
          address {
            fullAddress
            zipcode
            geometry {
              type
              latitude
              longitude
            }
          }
        }
        activeLocations {
          id
          name
          branch {
            name
            id
          }
          address {
            fullAddress
            zipcode
            geometry {
              type
              latitude
              longitude
            }
          }
        }
        operatingHours {
          day
          dayIndex
          pickupFromTime
          pickupToTime
          returnFromTime
          returnToTime
          closed
        }
        minBookingDuration
        minimumBookingAmount {
          type
          value
        }
        minBookingAmountOptIn
        minBookingDurationOptIn
        preBookingQuestions {
          question
          answerType
          required
          expectedAnswer
          options
          errorMessage
          B2B2C
        }
        holidays {
          id
          name
          startDate
          endDate
          isActive
        }
        addTaxOptIn
        taxes {
          id
          title
          rate
          type
          value
          isVehicleGroupSpecific
          vehicleGroups
          subdivisionOptIn
          subdivisionType
          subdivisionRate
          subdivisionValue
        }
        vehiclePreparationTime
      }
      branding {
        brandColors
        buttonColor
        accentColor
      }
      isActive
      tenancy {
        chargebeeAddons
        loginEnabled
      }
      organisation {
        id
        autoRateType
        autoChargeEnabled
        closeGroupSharingEnabled
        enableCostCenter
        maxImagesPerDamage
        costCenters {
          name
          projectIds
        }
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        locale
        name
        emailId
        phoneNumber {
          phone
          country
        }
        termsAndConditionsUrl
        requiresCustomerVerification
        allowSubscriptionBookings
        crossLocationBookingEnabled
        allowCarSharing
        logoUrl
        owner {
          id
          email
        }
        currency
        orgVatNumber
        orgRegNumber
        stripeAccountId
        allowSubscriptionBookings
        convergeEnabled
        extendedTestDriveMaxHours
      }
      webAppSetting {
        websiteTitle
        websiteIcon
        websiteLogo
        domain
        websiteHeader
        websiteDescription
        logoSize
        htmlDescription
        hasCarousel
        carousel
        hasGallery
        gallery
        websiteAddress
        googleGTMId
        enableBusinessCustomerSignup
        paymentGateway
      }
      mobileAppSetting {
        appIcon
        companyIcon
        straplineIcon
        landingPageIcon
      }
      disableSelfRegistration
    }
  }
`;
